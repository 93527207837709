<template>
<div>
    <el-row class="top-wrp">
        <el-col :span="8">
            <div class="logo"><img src="../assets/logow.png" alt="" srcset=""></div>
        </el-col>
        <el-col :span="16">
            <el-row>
                <el-col :span="12">
                    <el-input placeholder="输入商品名关键词" class="searchw-wrp">
                        <template #append>
                            <el-button icon="el-icon-search"></el-button>
                        </template>
                    </el-input>
                </el-col>
                <el-col :span="6">
                    <router-link to="/cart">
                        <el-button type="danger">购物车</el-button>
                    </router-link>
                </el-col>
                <el-col :span="6" class="links" v-if="loginState !=1">
                    <router-link to="/register">注册</router-link>
                    <router-link to="/login">登录</router-link>
                </el-col>
                <el-col :span="6" class="links" v-else>
                    <router-link class="username" to="/center">{{member.Member_Name}}</router-link>
                    <a href="javascript:;" @click="loginOut">登出</a>
                </el-col>
            </el-row>
        </el-col>
    </el-row>
    <div class="nav-wrp">
        <nav class="nav custom-width">
            <el-row>
                <el-col :span="4">
                    <router-link to="/home">首页</router-link>
                </el-col>
                <el-col :span="4" id="navOut">
                    <a href="javascript:;">分类</a>
                    <ul class="nav-sub">
                        <li>
                            <router-link to="/classes/7">亲子产品</router-link>
                            <router-link to="/classes/8">研学产品</router-link>
                            <router-link to="/classes/9">科普实验</router-link>
                            <router-link to="/classes/10">户外拓展</router-link>
                        </li>
                    </ul>
                </el-col>
                <el-col :span="4">
                    <a @click.prevent="jump">个人中心</a>
                </el-col>
                <el-col :span="4">
                    <router-link to="/secondhand">同城服务</router-link>
                </el-col>
                <el-col :span="4">
                    <router-link to="/carton">商家入驻</router-link>
                </el-col>

                <el-col :span="4">
                    <a href="javascript:void(0)" @click="routeJump">信息发布</a>
                </el-col>
            </el-row>
        </nav>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            loginState: "",
            member: ""
        };
    },
    created() {
        this.loginState = sessionStorage.getItem("loginState");
        this.member = JSON.parse(sessionStorage.getItem("member"));
    },
    methods: {
        routeJump(){
            let info = JSON.parse(sessionStorage.getItem("member")) 
            console.log("info",info)
            
            if(this.loginState != 1 && info == null) {
                this.$message("请先商家账户登录，再发布信息")
                this.$router.push({path:"/login"})
            }

            else if(info.hasOwnProperty("Member_ID")==true){
                this.$message("商家账户登录后，可发布信息")
            }
            else{
                this.$router.push({path:"/shopManage/cityServe"})

            }
        },
        loginOut() {
            sessionStorage.setItem("loginState", 0);
            sessionStorage.removeItem("member")
            this.clearAllCookie()
            this.$message("登出成功");
            this.$router.push({
                path: "/login"
            });
        },
        jump() {
            if (this.loginState != 1) {
                this.$message("请先登录");
                return false;
            }
            this.$router.push({
                path: "/center"
            });
        }
    }
};
</script>

<style scoped>
#navOut {
    position: relative;
}

.nav-sub {
    display: none;
    padding: 0;
    margin: 0;
    list-style: none;
    position: absolute;
    top: 35px;
    left: 0;
    width: 100%;
    text-align: center;
    z-index: 999;
    background-color: #f56c6c;
}

#navOut:hover .nav-sub {
    display: block;
}

.username {
    color: lightsalmon;
}

.logo img {
    height: 40px;
}

.top-wrp {
    padding: 10px;
}

.searchw-wrp {
    width: 90%;
}

.links a {
    line-height: 40px;
    margin: 0 8px;
    text-align: center;
}

.nav-wrp {
    background-color: #f56c6c;
}

nav {
    margin: 10px auto;
}

nav a {
    display: block;
    width: 100px;
    margin: 0 auto;
    text-align: center;
    line-height: 40px;
    color: #fff;
}

nav a:hover {
    background-color: rgba(0, 0, 0, 0.1);
}
</style>
