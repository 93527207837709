<template>
<div class="foot-wrp">
    <el-row class="foot custom-width">
        <el-col :span="8">
            Copyright © {{nowYear}} www.wzsosu.com All rights reserved
        </el-col>
        <el-col :span="8" class="cert-wrap">
            <img class="cert-logo" src="../assets/certificate.png" alt="certificate">
            <router-link to="/certification">企业相关资质 </router-link>
        </el-col>
        <el-col :span="8">
            <a href="https://beian.miit.gov.cn/#/Integrated/index"> 浙ICP备15026163号-1</a>
        </el-col>
    </el-row>
</div>
</template>

<script>
export default {
    data() {
        return {
            nowYear: ""
        }
    },
    created() {
        let now = new Date()
        this.nowYear = now.getFullYear()
    }
}
</script>

<style scoped>
.cert-wrap{
    display: flex;
    justify-content:center;
    align-items:center;
    text-align:center;
    font-weight:bold
}
.cert-wrap img{
    margin-right: 6px
}
.foot-wrp {
    background-color: rgb(179, 179, 179);
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    color: #fff;
}
.cert-logo{
    width: 16px;
}
.foot {
    font-size: 12px;
    line-height: 47px;
}
</style>
