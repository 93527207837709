<template>
<div>
    <Nav />
    <router-view />
    <Foot />
</div>
</template>

<script>
import Foot from '@/components/Footer'
import Nav from "@/components/Nav"

export default {
    data() {
        return {

        }
    },
    mounted(){
        this.$router.push("/home")
    },
    components: {
        Foot,
        Nav
    }
}
</script>

<style scoped>

</style>
